<template>
  <div class="flex">
    <div class="text-not-found">
      <p class="f36">
        Lo sentimos el <br />
        sitio está en mantenimiento
      </p>
      <div class="line"></div>
      <p class="f20">
        Estamos haciendo mantenimiento <br />
        para que todo funcione perfecto para ti.
      </p>
      <p class="f20">
        Estaremos de vuelta en unas horas.
      </p>
    </div>
    <div class="images">
      <img src="/img/icons/base_mockup.png" class="base" />
      <!-- <img src="/img/icons/404.png" class="error" /> -->
      <img src="/img/icons/circulos-celestes.svg" class="layer2 animate2" data-aos="zoom-in-up" />
      <img src="/img/icons/engranajes.png" class="layer1" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  // methods: {
  //   go() {
  //     this.$router.push('/app')
  //   },
  // },
}
</script>

<style scoped lang="scss">
@keyframes scales {
  from {
    transform: scale(.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scales2 {
  0% {
    transform: scale(.8);
    opacity: 0;
  }

  33% {
    transform: scale(.95);
    opacity: 1;
  }

  66% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1.25);
    opacity: 0;
  }
}

.flex {
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: linear-gradient(164deg, rgba(255, 255, 255, 1) 50%, rgba(218, 246, 250, 1) 90%);
}

.line {
  top: 380px;
  width: 264px;
  height: 4px;
  margin-top: 40px;
  margin-left: -150px;
  background: #056ee0;
}

.layer2 {
  top: 28%;
  right: -4%;
  height: 320px;
  -webkit-animation: scales2 4s linear infinite;
  animation: scales2 4s linear infinite;
}

.layer1 {
  z-index: 9;
  top: 24%;
  right: 108px;
  height: 270px;
  -webkit-animation: scales 3s linear infinite alternate;
  animation: scales 3s linear infinite alternate;
}

.text-not-found {
  width: 600px;
  margin-top: 150px;
  margin-left: 150px;
  font-family: $gordita;
  font-weight: 500;
}

.f64 {
  color: #282828;
  font-size: 64px;
}

.f36 {
  color: #282828;
  font-size: 36px;
  line-height: 47px;
}

.f20 {
  margin-top: 70px;
  color: #282828;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}

.underline {
  margin-top: 100px;
  color: #056ee0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;

  span {
    text-decoration-line: underline;
  }

  .md-icon {
    margin-left: 10px;
    transform: rotate(-180deg);
  }
}

.images {
  position: relative;
  width: calc(100% - 600px);
  min-width: 50vw;
  height: 700px;
  margin-top: auto;
  margin-left: auto;

  img {
    position: absolute;
  }

  .base {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 600px;
  }

  .error {
    right: 0;
    height: 320px;
    transform: translateY(63%);
  }
}
</style>
